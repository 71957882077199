<!--
 * @Author: 汪俊洪
 * @Date: 2021-08-31 19:42:43
 * @LastEditors: 汪俊洪
 * @LastEditTime: 2021-08-31 19:47:19
 * @Description: 数据检测分析
-->
<template>
  <Tab :list="tabList" :current="2" />
  <div class="list_wrap flex-1 flex-col">
    <div class="top">
      <h2>食品安全检测分析（近15天）</h2>
      <div class="list">
        <div class="item" v-for="(item, index) in total" :key="index">
          <span>{{ item.label }}</span>
          <br />
          <span class="value">{{ item.value }}</span>
        </div>
      </div>
    </div>
    <div id="chart" class="flex-1"></div>
  </div>
</template>

<script>
import Tab from '@/components/tab/index.vue'
import { reactive, ref } from '@vue/reactivity'
import { homeTabList } from '@/utils/tab'
// 引入echarts
import * as echarts from 'echarts'
import { onMounted } from 'vue'
import { getAnalysis } from '@/api/home'

export default {
  components: {
    Tab,
  },
  setup() {
    const tabList = reactive(homeTabList)
    //汇总信息
    const total = ref([
      {
        label: '检测总量(批次)',
        value: '0',
      },
      {
        label: '检测合格总量(批次)',
        value: '0',
      },
      {
        label: '检测总量(批次)',
        value: '0%',
      },
    ])

    function fontSize(res) {
      let clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      if (!clientWidth) return
      let fontSize = clientWidth / 375
      // console.log(fontSize * res)
      return res * fontSize
    }

    const setChart = (res) => {
      let xAxis = []
      let series = []
      let checkCount = 0, positiveCount = 0
      res.forEach((item) => {
        xAxis.push(item.checkTime)
        series.push((item.checkCount - item.positiveCount) / item.checkCount * 100)
        // 检测总数统计
        checkCount += item.checkCount
        positiveCount += item.checkCount
      })
      total.value[0].value = checkCount
      total.value[1].value = positiveCount
      total.value[2].value = (checkCount - positiveCount) / checkCount * 100 || 0 + '%'

      // 需要获取到element,所以是onMounted的Hook
      let myChart = echarts.init(document.getElementById('chart'))
      // 绘制图表
      myChart.setOption({
        title: {
          text: '食品安全检测合格率趋势(近15天)',
          x: '2%',
          y: '3%',
          textStyle: {
            fontSize: fontSize(12),
            color: '#333', // 主标题文字颜色
          },
        },
        tooltip: {  
          trigger: 'axis',
          formatter: function (params) {
            let str = params[0].axisValueLabel + '号' + '<br />'
            params.forEach((item) => {
              str +=
                '<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;left:5px;background-color:' +
                item.color +
                '"></span>' +
                item.seriesName +
                ' : ' +
                item.data +
                '%' +
                '<br />'
            })
            return str
          },
        },
        xAxis: {
          data: xAxis,
          axisLabel: {
            show: true,
            textStyle: {
              color: '#999', //更改坐标轴文字颜色
              fontSize: fontSize(10), //更改坐标轴文字大小
            },
          },
          axisLine: {
            lineStyle: {
              color: '#999', //更改坐标轴颜色
            },
          },
        },
        grid: {
          top: '16%', // 等价于 y: '16%'
          left: '3%',
          right: '3%',
          bottom: '3%',
          containLabel: true,
        },
        yAxis: {
          axisLabel: {
            show: true,
            formatter: '{value}%',
            textStyle: {
              color: '#999', //更改坐标轴文字颜色
              fontSize: fontSize(8), //更改坐标轴文字大小
            },
          },
        },
        series: [
          {
            name: '合格率',
            type: 'line',
            data: series,
            symbol: 'circle',
            symbolSize: fontSize(6),
            itemStyle: {
              normal: {
                // 拐点上显示数值
                label: {
                  show: true,
                  formatter: '{c}%',
                  textStyle: {
                    color: '#999', //更改坐标轴文字颜色
                    fontSize: fontSize(7), //更改坐标轴文字大小
                  },
                },
                lineStyle: {
                  width: fontSize(1), // 设置线宽
                  color: '#1b51f1',
                },
              },
            },
          },
        ],
      })
      window.onresize = function () {
        // 自适应大小
        myChart.resize()
      }
    }

    onMounted(() => {
      getAnalysis().then((res) => {
        setChart(res.result)
      })
    })

    return {
      tabList,
      total,
    }
  },
}
</script>

<style lang="less" scoped>
.list_wrap {
  padding: 12px;
  background: #f3f4f5;
  .top {
    padding: 12px 0;
    margin-bottom: 12px;
  }
  h2 {
    margin: 0 0 10px 10px;
    font-size: 16px;
    font-weight: normal;
  }
  .list {
    display: flex;
    .item {
      flex: 1;
      text-align: center;
      .value {
        font-size: 23px;
        color: #666;
      }
    }
  }
  > div {
    background: #fff;
    border-radius: 4px;
  }
}
</style>
